import Nav from "./components/Nav";
import style from "./Album.module.css";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { API_URL } from "./components/API-URL";

const ImgContainer = ({ img, reloadZoom, setZoom }) => {
    const [zoomed, setZoomed] = useState(false);

    useEffect(() => {
        setZoomed(false);
    }, [reloadZoom]);

    const doZoom = () => {
        setZoomed(true);
        setZoom(true);
    };

    return (
        <div
            className={style.imgContainer}
            style={{
                transform: zoomed ? "scale(1.7)" : "",
                zIndex: zoomed ? "10" : "1",
                backgroundImage: `url(${img.item})`,
            }}
            onClick={doZoom}
        ></div>
    );
};

const Album = () => {
    const [reloadZoom, setReloadZoom] = useState(false);
    const [zoom, setZoom] = useState(false);
    const [pictures, setPictures] = useState([]);
    const [queryParams, setQueryParams] = useState(null)

    const navigate = useNavigate();

    const request = async (cat) => {
        if (cat === "") {
            setPictures([]);
            return;
        }
        const req = await fetch(`${API_URL}/api/pictures?cat=${cat}`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
            },
        });
        const data = await req.json();

        const orderedData = data.sort((a, b) => a.date < b.date ? 1 : -1);
        setPictures(orderedData);
    };

    useEffect(() => {
        window.scrollTo(0, 0);
        if (!queryParams) {
            setQueryParams(new URLSearchParams(window.location.search))
        } else {
            const id = queryParams.get("id");
            if (!id) {
                navigate("/mariage");
            }
            request(id);
        }
    }, [navigate, queryParams]);

    const closeZoom = () => {
        setReloadZoom(!reloadZoom);
        setZoom(false);
    };
    return (
        <div className={style.bodyDiv}>
            <Nav />
            <div className={style.MainDiv}>
                <div className={style.MainHead}>
                    <h1>{queryParams && queryParams.get("type")}</h1>
                    <h1>{queryParams && queryParams.get("name").replaceAll("_", "&")}</h1>
                </div>
                <div className={style.grid}>
                    {pictures.map((pic, index) => (
                        <ImgContainer
                            key={index}
                            img={pic}
                            reloadZoom={reloadZoom}
                            setZoom={setZoom}
                        />
                    ))}
                </div>
            </div>

            <div
                className={zoom ? style.openZoom : style.closedZooom}
                onClick={() => closeZoom()}
            ></div>
        </div>
    );
};

export default Album;
