import style from "./NavLight.module.css";
import logo from "./../assets/logo.svg";
import { useNavigate } from "react-router-dom";
import React from "react";

const Nav = () => {
    const navigate = useNavigate();

    return (
        <nav className={style.navbar}>
            <div id="navbarNav">
                <ul className={style.navbar_nav}>
                    <li className={style.nav_item_logo}>
                        <button className={style.nav_logo_link} onClick={() => {navigate('/')}}>
                            <img
                                src={logo}
                                className={style.nav_logo}
                                alt="logo"
                            />
                        </button>
                    </li>
                    <input
                        className={style.checkbox}
                        type="checkbox"
                        name=""
                        id=""
                    />

                    <div className={style.hamburger_lines}>
                        <span className={style.line1} id="line1"></span>
                        <span className={style.line2} id="line2"></span>
                        <span className={style.line3} id="line3"></span>
                    </div>

                    <div className={style.items}>
                        <li className={style.nav_item}>
                            <button className={style.nav_link} onClick={() => {navigate('/moi')}}>
                                Qui suis-je ?
                            </button>
                        </li>
                        <li className={style.nav_item}>
                            <button
                                className={style.nav_link}
                                onClick={() => {navigate('/mariage')}}
                            >
                                Mariage
                            </button>
                        </li>
                        <li className={style.nav_item}>
                            <button
                                className={style.nav_link}
                                onClick={() => {navigate('/evenements')}}
                            >
                                Évènementiel
                            </button>
                        </li>
                        <li className={style.nav_item}>
                            <button
                                className={style.nav_link}
                                onClick={() => {navigate('/services')}}
                            >
                                Services
                            </button>
                        </li>
                        <li className={style.nav_item}>
                            <button
                                className={style.nav_link}
                                onClick={() => {navigate('/productions')}}
                            >
                                Productions
                            </button>
                        </li>
                        <li className={style.nav_item}>
                            <button
                                className={style.nav_green}
                                onClick={() => {navigate('/contact')}}
                            >
                                Me contacter
                            </button>
                        </li>
                    </div>
                </ul>
            </div>
        </nav>
    );
};

export default Nav;
