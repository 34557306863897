import React, { useEffect, useState } from "react";
import style from "./add-location.module.css";
import { API_URL } from "../components/API-URL";
import DeleteIcon from "@mui/icons-material/Delete";
import { useNavigate } from "react-router-dom";
import BackArrow from "../components/back-arrow";

function Location() {
    const [files, setFiles] = useState([]);
    const [reload, setReload] = useState(false);
    const [buttonDisabled, setButtonDisabled] = useState(false);
    const [title, setTitle] = useState("");
    const [description, setDescription] = useState("");
    const [price, setPrice] = useState("");

    const navigate = useNavigate();

    const removeFile = (index) => {
        const tmp = files;
        tmp.splice(index, 1);
        setFiles(tmp);
        setReload(!reload);
    };

    const appendFiles = async (newFiles, event) => {
        event.preventDefault();
        const tmp = files;
        const readFileAsDataURL = (file) =>
            new Promise((resolve, reject) => {
                const reader = new FileReader();

                reader.onloadend = () => resolve(reader.result);
                reader.onerror = reject;

                reader.readAsDataURL(file);
            });

        for (let i = 0; i < newFiles.length; i++) {
            try {
                const preview = await readFileAsDataURL(newFiles[i]);
                tmp.push({ img: newFiles[i], preview });
            } catch (error) {
                console.error("Erreur lors de la lecture du fichier", error);
            }
        }

        setFiles(tmp);
        document.getElementById("images").value = "";
        setReload(!reload);
    };

    useEffect(() => {
        if (!sessionStorage.getItem("token")) {
            navigate("/");
        }
        const dropContainer = document.getElementById("dropcontainer");

        dropContainer.addEventListener(
            "dragover",
            (e) => {
                e.preventDefault();
            },
            false
        );

        dropContainer.addEventListener("dragenter", () => {
            dropContainer.classList.add("drag-active");
        });

        dropContainer.addEventListener("dragleave", () => {
            dropContainer.classList.remove("drag-active");
        });

        dropContainer.addEventListener("drop", (e) => {
            dropContainer.classList.remove("drag-active");
            appendFiles(e.dataTransfer.files, e);
        });
    }, []);

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (buttonDisabled) return;
        setButtonDisabled(true);

        const out = (
            await Promise.all(
                files.map(async (file, i) => {
                    try {
                        const response = await fetch(
                            API_URL + "/api/bo/pictures",
                            {
                                method: "POST",
                                headers: {
                                    "Content-Type": "application/json",
                                    Authorization: `Bearer ${sessionStorage.getItem(
                                        "token"
                                    )}`,
                                },
                                body: JSON.stringify({
                                    cat: "loc",
                                    contentType: file.img.type,
                                }),
                            }
                        );
                        if (response.status !== 200) {
                            console.error(
                                "Erreur lors de l'upload de l'image:",
                                file.name
                            );
                            return;
                        }
                        const data = await response.json();

                        await fetch(data.url, {
                            method: "PUT",
                            body: file.img,
                            headers: {
                                "Content-Type": file.img.type,
                            },
                        });
                        return { index: i, id: data.id };
                    } catch (error) {
                        console.error(
                            "Erreur lors de l'upload de l'image:",
                            file.name,
                            error
                        );
                    }
                })
            )
        ).sort((a, b) => a.index - b.index);
        const locReq = await fetch(API_URL + "/api/bo/loc", {
            method: "POST",
            headers: {
                Authorization: `Bearer ${sessionStorage.getItem("token")}`,
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                title,
                description,
                price: parseFloat(price),
                picturesId: out.map((o) => o.id),
            }),
        });

        if (locReq.status === 200) {
            navigate("/menu");
        } else {
            console.error("Erreur lors de la création de la location");
            setButtonDisabled(false);
        }
    };

    return (
        <div className={style.bodyDiv}>
            <BackArrow to={"/menu"} />
            <div className={style.UploadMainContainer}>
                <h1 className={style.title}>Ajouter une location</h1>
                <div className={style.UploadContainer}>
                    <div className={style.form}>
                        <label
                            htmlFor="images"
                            className={style.drop_container}
                            id="dropcontainer"
                            style={{ width: "92%" }}
                        >
                            <span className={style.drop_title}>
                                Déposez vos images ici
                            </span>
                            ou
                            <input
                                type="file"
                                id="images"
                                accept="image/png, image/jpeg, image/gif"
                                multiple
                                required
                                onChange={(e) => appendFiles(e.target.files, e)}
                                className={style.inputFile}
                            />
                        </label>
                        {files.length > 0 && (
                            <div style={{ width: "100%" }}>
                                <div className={style.imagesPreview}>
                                    {files.map((file, index) => {
                                        return (
                                            <div
                                                className={
                                                    style.singleImgContainer
                                                }
                                                key={index}
                                            >
                                                <div
                                                    className={style.removeImg}
                                                    onClick={() =>
                                                        removeFile(index)
                                                    }
                                                >
                                                    <DeleteIcon
                                                        style={{
                                                            color: "#BA6868",
                                                        }}
                                                    />
                                                </div>
                                                <img
                                                    key={index}
                                                    src={file.preview}
                                                    alt="Image Preview"
                                                    style={{
                                                        height: "100px",
                                                    }}
                                                />
                                            </div>
                                        );
                                    })}
                                </div>
                                <div
                                    style={{
                                        width: "100%",
                                        display: "flex",
                                        flexDirection: "column",
                                        alignItems: "center",
                                    }}
                                >
                                    <input
                                        type="text"
                                        placeholder="Titre"
                                        value={title}
                                        onChange={(e) =>
                                            setTitle(e.target.value)
                                        }
                                    />
                                    {title.length !== 0 && (
                                        <>
                                            <textarea
                                                placeholder="Description"
                                                value={description}
                                                onChange={(e) =>
                                                    setDescription(
                                                        e.target.value
                                                    )
                                                }
                                            />
                                            {description.length !== 0 && (
                                                <>
                                                    <input
                                                        type="text"
                                                        placeholder="Prix"
                                                        value={price}
                                                        onChange={(e) => {
                                                            const inputValue =
                                                                e.target.value;
                                                            const regex =
                                                                /^[0-9,.]*$/;
                                                            if (
                                                                regex.test(
                                                                    inputValue
                                                                )
                                                            ) {
                                                                setPrice(
                                                                    inputValue
                                                                );
                                                            }
                                                        }}
                                                    />
                                                    {price && (
                                                        <>
                                                            <button
                                                                className={
                                                                    style.submit
                                                                }
                                                                type="submit"
                                                                disabled={
                                                                    buttonDisabled
                                                                }
                                                                onClick={
                                                                    handleSubmit
                                                                }
                                                            >
                                                                Valider
                                                            </button>
                                                        </>
                                                    )}
                                                </>
                                            )}
                                        </>
                                    )}
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Location;
