import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from "react-router-dom";

const BackArrow = ({ to }) => {
    const navigate = useNavigate();

    return (
        <div
            style={{
                position: "absolute",
                top: "50px",
                left: "50px",
                width: "40px",
                height: "40px",
                border: "2px solid #0d3527",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                cursor: "pointer",
                borderRadius: "50%",
            }}
            onClick={() => {
                if (to) navigate(to);
            }}
        >
            <ArrowBackIcon style={{ width: "40px" }} />
        </div>
    );
};

export default BackArrow;
