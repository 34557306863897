import { useNavigate } from "react-router-dom";
import style from "./delete-picture.module.css";
import { useState, useEffect } from "react";
import { API_URL } from "../components/API-URL";
import BackArrow from "../components/back-arrow";
import DeleteIcon from "@mui/icons-material/Delete";

const ImgContainer = ({ img, selected, setSelected }) => {
    const [zoomed, setZoomed] = useState(false);

    const toggleZoom = () => {
        if (zoomed) {
            let tmp = selected;
            tmp.splice(tmp.indexOf(img), 1);
            setSelected(tmp);
            setZoomed(false);
        } else {
            let tmp = selected;
            tmp.push(img);
            setSelected(tmp);
            setZoomed(true);
        }
    };
    return (
        <div
            className={style.imgContainer}
            style={{
                transform: zoomed ? "scale(0.9)" : "",
                border: zoomed ? "2px solid #0d3527" : "",
                backgroundImage: `url(${img.item})`,
            }}
            onClick={toggleZoom}
        ></div>
    );
};

const DeletePicture = () => {
    const [pictures, setPictures] = useState([]);
    const [selected, setSelected] = useState([]);
    const navigate = useNavigate();
    const [disable, setDisable] = useState(false);

    const request = async (cat) => {
        if (cat === "") {
            setPictures([]);
            return;
        }
        const req = await fetch(`${API_URL}/api/pictures?cat=${cat}`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${sessionStorage.getItem("token")}`,
            },
        });
        const data = await req.json();
        setPictures(data);
    };

    const deletePictures = async () => {
        if (disable) return;
        setDisable(true);
        if (selected.length === 0) {
            return;
        }
        const req = await fetch(`${API_URL}/api/bo/pictures`, {
            method: "DELETE",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${sessionStorage.getItem("token")}`,
            },
            body: JSON.stringify({ pictures: selected }),
        });
        const data = await req.json();
        setDisable(false);
        setSelected([]);
        if (data.msg === "ok") {
            navigate("/menu");
        }
    };

    useEffect(() => {
        if (!sessionStorage.getItem("token")) {
            navigate("/");
        }
    }, []);

    return (
        <div className={style.bodyDiv}>
            <div
                className={style.deleteButton}
                disabled={disable}
                onClick={deletePictures}
            >
                <DeleteIcon style={{ color: "white", fontSize: "2em" }} />
            </div>
            <BackArrow to={"/menu"} />
            <div className={style.MainDiv}>
                <h1 className={style.title}>Selectionnez les photos</h1>
                <h1 className={style.titleSecond}>à supprimer </h1>

                <div
                    style={{
                        height: "10em",
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                    }}
                >
                    <select
                        className={style.catSelector}
                        onChange={(e) => request(e.target.value)}
                    >
                        <option value="">--Choisir une catégorie--</option>
                        <option value="nature">Nature</option>
                        <option value="chic">Chic</option>
                        <option value="champetre">Champêtre</option>
                        <option value="boeme">Boème</option>
                        <option value="color">Tout en couleur</option>
                        <option value="theme">À Thème</option>
                        <option value="accessoires">Accessoires</option>
                        <option value="bouquet">Bouquets de Mariée</option>
                        <option value="structures">
                            Structures & panneaux
                        </option>
                        <option value="ceremonies">Cérémonies</option>
                        <option value="decorations">
                            Décorations de salle
                        </option>
                    </select>
                </div>

                <div className={style.grid}>
                    {pictures.map((pic, index) => (
                        <ImgContainer
                            key={index}
                            img={pic}
                            selected={selected}
                            setSelected={setSelected}
                        />
                    ))}
                </div>
            </div>
        </div>
    );
};

export default DeletePicture;
