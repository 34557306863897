import style from "./ContactButton.module.css";
import React from "react";
import { useNavigate } from "react-router-dom";

const ContactButton = () => {
    const navigate = useNavigate();
    return (
        <div className={style.container}>
            <div className={style.ContactButton}>
                <button onClick={() => navigate("/contact")} >Contactez-moi</button>
            </div>
        </div>
    );
};

export default ContactButton;
