import React from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";

import Accueil from './Accueil';
import Moi from './Moi'
import Evenements from './Evenements';
import Contact from './Contact';
import Services from './Services';
import Mariage from './Mariage';
import Productions from './Productions';
import Locations from './Locations';
import Album from './Album';
import UploadPicure from './backOffice/upload-picture';
import Login from './backOffice/login';
import Menu from './backOffice/menu';
import DeletePciture from './backOffice/delete-picture';
import AddProduct from './backOffice/add-location';
import UpdateLoc from './backOffice/update-del-location';
import SelectUpdateLoc from './backOffice/select-update-loc';
import NotFound from './NotFound';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Accueil />} />
        <Route path="/moi" element={<Moi />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/evenements" element={<Evenements />} />
        <Route path="/mariage" element={<Mariage />} />
        <Route path="/services" element={<Services />} />
        <Route path="/productions" element={<Productions />} />
        <Route path="/locations" element={<Locations />} />
        <Route path="/album" element={<Album />} />
        <Route path="/upload-picture" element={<UploadPicure />} />
        <Route path='/connexion' element={<Login />} />
        <Route path='/menu' element={<Menu />} />
        <Route path='/delete-picture' element={<DeletePciture />} />
        <Route path='/add-product' element={<AddProduct />} />
        <Route path='/select-update-product' element={<SelectUpdateLoc />} />
        <Route path='/update-product' element={<UpdateLoc />} />
        <Route path='/*' element={<NotFound />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;


