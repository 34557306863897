import Nav from "./components/Nav";
import style from "./NotFound.module.css";
import { useNavigate } from "react-router-dom";

const NotFound = () => {
    const navigate = useNavigate();

    return (
        <div className={style.bodyDiv}>
            <Nav />
           <div className={style.MainDiv}>
                <p>Vous semblez perdu</p>

                <img/>
                <button onClick={() => {navigate("/")}} >Retour a l'acceuil</button>
            </div>
        </div>
    );
};

export default NotFound;
