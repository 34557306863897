import Nav from "./components/Nav";
import style from "./evenements.module.css";
import ContactButton from "./components/ContactButton";

import img1 from "./assets/anniversaire/3.jpeg";
import img2 from "./assets/anniversaire/1.jpeg";
import img3 from "./assets/anniversaire/2.jpeg";

import img4 from "./assets/bapteme/1.jpeg";
import img5 from "./assets/bapteme/2.jpeg";
import img6 from "./assets/bapteme/3.jpeg";

import img7 from "./assets/calendaire/3.jpeg";
import img8 from "./assets/calendaire/1.jpeg";
import img9 from "./assets/calendaire/5.jpeg";
import img10 from "./assets/calendaire/4.jpeg";
import img11 from "./assets/calendaire/2.jpeg";

import img12 from "./assets/deces/1.jpeg";
import img13 from "./assets/deces/3.jpeg";
import img14 from "./assets/deces/2.jpeg";

import lis1 from "./assets/lisianthus/lis2.svg";
import lis2 from "./assets/lisianthus/lis3.svg";

const Evenements = () => {
    return (
        <div className={style.bodyDiv}>
            <img className={style.lisianthus1} src={lis1} alt="" />
            <img className={style.lisianthus2} src={lis2} alt="" />
            <Nav />
            <div className={style.MainDiv}>
                <h1>Événementiel</h1>
                <p className={style.intro}>
                    Tout au long de l'année, mes services sont à votre
                    disposition pour décorer votre chez vous ou celui de votre
                    entourage. Une fête ou juste une envie de fleurir votre
                    maison, mes fleurs sont faites pour vous !
                </p>
                <div className={style.partContainer}>
                    <h2>Anniversaires</h2>
                    <p>
                        Un bouquet à offrir ou à livrer, contactez -moi ! C’est
                        avec passion que je sélectionne les fleurs les plus
                        appropriées afin de créer de beaux bouquets pour les
                        anniversaires. Des fêtes d’anniversaires à thèmes
                        peuvent également être organisées avec plusieurs options
                        de décorations, d’animations florales et de thèmes tels
                        que le Western, les années 80, Harry Potter et bien
                        d'autres encore…
                    </p>
                    <div className={style.imgContainer}>
                        <div className={style.centerer1}>
                            <img alt="bouquet d'anniversaire" src={img1} className={style.img1} />
                            <img alt="composition d'anniversaire" src={img2} className={style.img2} />
                            <img alt="décoration d'anniversaire" src={img3} className={style.img3} />
                        </div>
                    </div>
                </div>

                <div className={style.partContainer}>
                    <h2>Baptêmes</h2>
                    <p>
                        Je peux vous proposer de magnifiques compositions de
                        table pour ces moments solennels qui resteront gravés
                        dans vos mémoires. Je vous donnerai des recommandations
                        pour sélectionner les fleurs, le style et les contenants
                        adaptés à vos besoins et vos envies.
                    </p>
                    <div className={style.imgContainer}>
                        <div className={style.centerer2}>
                            <img alt="décoration de table baptême" src={img4} className={style.img1} />
                            <img alt="décoration de table baptême" src={img5} className={style.img2} />
                            <img alt="décoration de table baptême" src={img6} className={style.img3} />
                        </div>
                    </div>
                </div>

                <div className={style.partContainer}>
                    <h2>Calendaire</h2>
                    <p>
                        Durant l'année, les évènements sont nombreux et je peux
                        agrémenter ces journées par de belles compositions de
                        fleurs coupées ou plantes. Je reste à votre disposition
                        pour toutes les célébrations de l’année afin de vous
                        fournir les compositions de fleurs et de plantes les
                        mieux appropriées à l'occasion de la St-Valentin, la
                        fêtes des mères, la Toussaint, Noël…
                    </p>
                    <div className={style.imgContainerFive}>
                        <div className={style.centerer3}>
                            <div className={style.line1}>
                                <img alt="composition de noël" src={img7} className={style.img1} />
                                <img alt="compostion toussaint" src={img8} className={style.img2} />
                            </div>
                            <div className={style.line2}>
                                <img alt="composition fêtes des mères" src={img9} className={style.img1} />
                            </div>
                            <div className={style.line1}>
                                <img alt="composition pâques" src={img10} className={style.img1} />
                                <img alt="composition saint-valentin" src={img11} className={style.img2} />
                            </div>
                        </div>
                    </div>
                </div>

                <div className={style.partContainer}>
                    <h2>Décès</h2>
                    <p>
                        Je vous accompagne avec compassion en vous proposant les
                        fleurs et les couleurs les mieux adaptées en mémoire du
                        défunt. Il est possible de créer divers dessus ou devant
                        de cercueil et les compositions de plantes de votre
                        choix.
                    </p>
                    <div className={style.imgContainer}>
                        <div className={style.centerer1}>
                            <img alt="gerbe funérailles" src={img12} className={style.img1} />
                            <img alt="décoration funérailles" src={img13} className={style.img2} />
                            <img alt="gerbe funérailles" src={img14} className={style.img3} />
                        </div>
                    </div>
                </div>
            </div>
            <ContactButton />
        </div>
    );
};

export default Evenements;
