import Nav from "./components/Nav";
import style from "./moi.module.css";
import ContactButton from "./components/ContactButton";

import img1 from "./assets/moi/2.jpeg";
import img2 from "./assets/moi/1.jpeg";

import lis1 from "./assets/lisianthus/lis6.svg";
import lis2 from "./assets/lisianthus/lis10.svg";

const Moi = () => {
    return (
        <div className={style.bodyDiv}>
            <img className={style.lisianthus1} src={lis1} alt="" />
            <Nav />
            <div className={style.MainDiv}>
                <div className={style.MainHead}>
                    <h1>Qui suis-je?</h1>
                </div>
                <p>
                    Originaire de Wattwiller, j'ai étudié l' horticulture,la
                    floriculture et acquis de l'expérience chez différents
                    producteurs au cours du temps. C'est donc avec passion que
                    j’ai ouvert mon entreprise de productions florales depuis
                    plusieurs années.
                </p>

                <div className={style.first}>
                    <div className={style.text}>
                        <p>
                            La proximité avec la nature me procure un réel
                            plaisir, elle me permet de cultiver toutes sortes de
                            plantes. Des fleurs coupées destinées à la
                            décoration, des fleurs comestibles ainsi que des
                            plantes aromatiques et médicinales. Étant dévouée et
                            respectueuse de l'environnement, ma culture est
                            locale, raisonnée et sans produits chimiques. Je
                            produis donc des fleurs de diverses couleurs et
                            tailles à Wattwiller tout au long de l’année.
                        </p>
                    </div>
                    <img className={style.image} src={img1} alt="Armelle Ramstein" />
                </div>

                <div className={style.second}>
                    <img className={style.image} src={img2} alt="Armelle Ramstein" />
                    <div className={style.text}>
                        <p>
                            Les fleurs coupées que je cultive me permettent de
                            réaliser les compositions que vous souhaitez. Elles
                            sont cueillies tôt le matin et stockées dans un
                            local afin de conserver au mieux leur fraîcheur.
                        </p>
                    </div>
                </div>
                <div className={style.sign}>
                    <h2>Armelle Ramstein</h2>
                </div>
                <div className={style.endHead}>
                    <h2>J' attends vos projets avec impatience!</h2>
                </div>
            </div>
            <img className={style.lisianthus2} src={lis2} alt="" />
            <ContactButton />
        </div>
    );
};

export default Moi;
