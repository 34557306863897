import style from "./Services.module.css";
import Nav from "./components/Nav";
import ContactButton from "./components/ContactButton";
import { useNavigate } from "react-router-dom";

import img1 from "./assets/services/1.jpeg";
import img2 from "./assets/services/2.jpeg";
import img3 from "./assets/services/3.jpeg";

import lis1 from "./assets/lisianthus/lis8.svg";

const Services = () => {
    const navigate = useNavigate();
    return (
        <div className={style.bodyDiv}>
            <img className={style.lisianthus1} src={lis1} alt="" />
            <Nav />
            <div className={style.MainDiv}>
                <div className={style.MainHead}>
                    <h1>Services</h1>
                </div>
                <p>
                    Mes services sont mis à disposition autant pour les
                    particuliers que pour les professionnels. Vous souhaitez
                    bénéficier d’une décoration florale pour agrémenter vos
                    évènements ? Mon matériel de location est disponible pour
                    décorer vos salles et vos événements. Je peux vous mettre à
                    disposition des plantes vertes et fleuries pour décorer vos
                    salles. Il est aussi possible d’agrémenter vos tables et
                    buffets de compositions florales. Je peux créer de belles
                    compositions pour vos tables et buffets, et bien entendu
                    vous faire la mise en place. N’hésitez pas à me contacter
                    pour plus de renseignements.
                </p>

                <div className={style.second}>
                    <img className={style.image} src={img1} alt="Armelle Ramstein"/>
                    <div className={style.text}>
                        <div className={style.spacer}>
                            <h2>Vente</h2>
                            <p>
                                Pour toute commande, il est préférable
                                d’effectuer votre commande 48h à l’avance afin
                                de garantir un service de qualité et de mieux
                                répondre à vos besoins.
                            </p>
                        </div>
                    </div>
                </div>

                <div className={style.first}>
                    <div className={style.text}>
                        <div className={style.spacer}>
                            <h2>Location</h2>
                            <p>
                                Je vous propose une multitude d’accessoires et
                                de contenants pour réaliser avec élégance ce que
                                vous souhaitez pour vos événements.
                            </p>
                            <button
                                onClick={() => navigate("/locations")}
                                className={style.locationButton}
                            >
                                Voir toutes mes locations
                            </button>
                        </div>
                    </div>
                    <img className={style.image} src={img2} alt="structure de mariage en location" />
                </div>

                <div className={style.second}>
                    <img className={style.image} src={img3} alt="Armelle Ramstein" />
                    <div className={style.text}>
                        <div className={style.spacer}>
                            <h2>Mise en place &</h2>
                            <h2>rangement</h2>
                            <p>
                                Pour tous vos événements je peux me déplacer et
                                effectuer la mise en place et le rangement de
                                vos décorations florales en fonction du lieu et
                                de mes disponibilités.
                            </p>
                        </div>
                    </div>
                </div>

                <div className={style.first}>
                    <div className={style.text}>
                        <div className={style.spacer}>
                            <h2>Livraisons</h2>
                            <p>
                                « Offrez des fleurs à ceux que vous aimez » Je
                                peux livrer pour vous bouquets et compositions
                                de plantes vertes et fleuries, sur simple
                                commande, 48h à l’avance, dans tout le secteur à
                                20 km autour de Wattwiller. Que ce soit pour
                                vous ou pour un proche, les livraisons sont
                                effectuées en mains propres, par mes soins, aux
                                heures que vous choisirez. Si vous le souhaitez,
                                il est possible de recevoir une photo de la
                                commande par mail ou SMS.
                            </p>
                        </div>
                    </div>
                    <img className={style.image} alt="" />
                </div>
            </div>
            <ContactButton />
        </div>
    );
};

export default Services;
