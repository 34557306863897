import { useEffect, useState } from "react";
import style from "./Locations.module.css";
import Nav from "./components/Nav";
import { API_URL } from "./components/API-URL";


const Item = ({ item }) => {
    return (
        <div className={style.itemDiv}>
            <div
                style={{
                    position: "relative",
                    height: "100%",
                    display: "flex",
                    alignItems: "center",
                }}
            >
                <div
                    className={style.itemPicture}
                    style={{
                        backgroundImage: `url(${item.pictures[0].item})`,
                    }}
                ></div>
            </div>
            <div className={style.itemText}>
                <div style={{ display: "flex", alignItems: "center" }}>
                    <h2 className={style.title} style={{ width: "60%" }}>{item.title}</h2>
                    <h3 className={style.price} style={{ width: "40%", textAlign: "end", margin: "0px" }}>
                        {item.price.toFixed(2)} €
                    </h3>
                </div>
                <p className={style.description}> {item.description}</p>
            </div>
        </div>
    );
};

const Locations = () => {
    const [locations, setLocations] = useState(null);
    const [displayedLocations, setDisplayedLocations] = useState(null);

    const request = async () => {
        const locReq = await fetch(`${API_URL}/api/locs`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
            },
        });
        const data = await locReq.json();
        const req = await fetch(`${API_URL}/api/pictures?cat=loc`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
            },
        });
        const dataPic = await req.json();
        data.forEach((loc) => {
            loc.pictures = loc.picturesId.map((id) => {
                return dataPic.find((pic) => pic.itemId === id);
            });
        });
        const locationOrdered = data.sort((a, b) => a.date < b.date ? 1 : -1);
        setLocations(locationOrdered);
        setDisplayedLocations(locationOrdered);
    };

    useEffect(() => {
        window.scrollTo(0, 0);
        if (!locations) request();
    }, []);


    const search = (e) => {
        const search = e.target.value.toLowerCase();

        const out = locations.filter(item => item.title.toLowerCase().includes(search.toLowerCase()));
        setDisplayedLocations(out);

    };

    return (
        <div className={style.bodyDiv}>
            <Nav />
            <div className={style.MainDiv}>
                <h1>Locations</h1>
                <input type="text" placeholder="Rechercher" onChange={search}/>
                {displayedLocations &&
                    displayedLocations.map((loc) => (
                        <Item item={loc} key={loc.itemId} />
                    ))}
            </div>
        </div>
    );
};

export default Locations;
