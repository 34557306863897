import { useNavigate } from "react-router-dom";
import style from "./menu.module.css";
import { useEffect } from "react";

const Menu = () => {
    const navigate = useNavigate();

    useEffect(() => {
        if (sessionStorage.getItem("token") === null) {
            navigate("/connexion");
        }
    });

    return (
        <div className={style.bodyDiv}>
            <div className={style.MainDiv}>
                <h1 className={style.title}>Que voulez vous faire ?</h1>

                <div className={style.selector}>
                    <div className={style.line}>
                        <div
                            className={style.button}
                            onClick={() => {
                                navigate("/upload-picture");
                            }}
                        >
                            Ajouter des photos
                        </div>
                        <div
                            className={style.button}
                            onClick={() => {
                                navigate("/add-product");
                            }}
                        >
                            Ajouter une location
                        </div>
                    </div>
                    <div className={style.line}>
                        <div
                            className={style.button}
                            onClick={() => {
                                navigate("/delete-picture");
                            }}
                        >
                            Supprimer des photos
                        </div>
                        <div
                            className={style.button}
                            onClick={() => {
                                navigate("/select-update-product");
                            }}
                        >
                            Supprimer / Modifier une location
                        </div>
                    </div>
                </div>
            </div>  
        </div>
    );
};

export default Menu;
